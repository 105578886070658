import {
  faChevronUp,
  faFileLines,
  faFilePen,
  faFileSignature,
  faLock,
  faLockOpen,
  faMobile,
  faSpinner,
  faTv,
  faUnlockKeyhole,
  faUserGraduate,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useParams } from "react-router-dom";
import { useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import Loader from "../../../MainComponents/Loader.jsx";
import HelmetTags from "../../../MainComponents/HelmetTags";
import SingleLectureCountDown from "./components/SingleLectureCountDown";

function StudentLecture() {
  const { lectureID } = useParams();
  function reducer(state, action) {
    switch (action.type) {
      case "setCollapse": {
        return {
          ...state,
          collapse: action.payload,
        };
      }
      case "setSrc": {
        return {
          ...state,
          src: action.payload,
        };
      }
      case "setCurrentVideo": {
        return {
          ...state,
          currentVideo: action.payload,
        };
      }
      case "setBuyLecture": {
        return {
          ...state,
          buyLecture: action.payload,
        };
      }
      case "setRefetch": {
        return {
          ...state,
          refetch: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    collapse: false,
    src: "",
    refetch: false,
    currentVideo: null,
    buyLecture: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  //!---- get lecture info -------
  const [LectureInfoSuccess, LectureInfoErrors, LectureInfoLoading] = useAxios(
    `${process.env.REACT_APP_STUDENT_LECTURE_INFO_API}${lectureID}`,
    "GET",
    lectureID,
    state.refetch
  );

  //!---- buy lecture  -------
  const [buyLectureSuccess, buyLectureErrors, submitLoading] = useAxios(
    process.env.REACT_APP_STUDENT_BUY_LECTURE_API,
    "POST",
    state.buyLecture.flag,
    state.buyLecture.dependency,
    state.buyLecture.data,
    true
  );

  //!---refetch the lecture data again if the code is correct to open the paid content

  useEffect(() => {
    if (buyLectureSuccess) {
      dispatch({
        type: "setRefetch",
        payload: !state.refetch,
      });
      reset();
    }
  }, [buyLectureSuccess]);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onTouched",
  });

  const handleBuyLecture = (data) => {
    dispatch({
      type: "setBuyLecture",
      payload: {
        flag: "buyLecture",
        dependency: !state.buyLecture.dependency,
        data: { ...data, id: lectureID },
      },
    });
  };
  if (LectureInfoLoading) {
    return <Loader />;
  }
  if (LectureInfoErrors && typeof LectureInfoErrors === "string") {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <p>{LectureInfoErrors}</p>{" "}
      </div>
    );
  }

  return (
    <section
      className={`width min-h-screen   py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="المحاضرات | Mr Hossam Shaaban"></HelmetTags>{" "}
      {/**
       * //!---- lecture-content and buying form -----
       */}
      <div className="lecture-top flex w-full justify-between gap-20 flex-col items-center ">
        {/**
         * //!------content-----
         */}{" "}
        <div
          dir="rtl"
          className={` flex flex-col w-full items-center text-center transition-{width} duration-500  ease-in-out md:w-full`}
        >
          <div
            className={`lecture-session-img-container  relative w-3/4 overflow-hidden rounded-[25px] border-2 border-secondary p-7  lg:w-full  h-[320px] max-h-[320px]  `}
          >
            <img
              className="h-full w-full cursor-pointer rounded-[20px] object-cover duration-300 hover:saturate-150"
              src={LectureInfoSuccess?.data?.session_info?.img}
              alt={LectureInfoSuccess?.data?.session_info?.title}
            />
            <div className="stage-wrapper absolute right-14 top-14 flex h-12 w-fit items-center justify-center gap-3 rounded-xl bg-light px-3 text-2xl  font-semibold">
              <FontAwesomeIcon className="h-6 w-6 " icon={faUserGraduate} />
              <p className="stage pointer-events-none text-2xl font-semibold">
                {LectureInfoSuccess?.data?.session_info?.stage === 3
                  ? "الصف الثالث"
                  : LectureInfoSuccess?.data?.session_info?.stage === 2
                  ? "الصف الثاني"
                  : "الصف الأول"}
              </p>
            </div>
          </div>
          <div
            className={`title-description mt-8 rounded-2xl border-2 border-secondary  p-5 w-3/4 text-center`}
          >
            <h1 className="lecture-session-title mb-9 text-4xl  font-bold">
              {LectureInfoSuccess?.data?.session_info?.title}
            </h1>
            <h2 className="lecture-session-description text-3xl">
              {LectureInfoSuccess?.data?.session_info?.description}
            </h2>
          </div>
          {LectureInfoSuccess?.data?.session_info?.is_buy === 1 && (
            <SingleLectureCountDown
              expireDate={LectureInfoSuccess?.data?.session_info.expire_at}
              dispatch={dispatch}
              state={state}
            />
          )}
        </div>
        {/**
         * //!------buying form-----
         */}
        {LectureInfoSuccess?.data?.session_info?.is_buy === 0 && (
          <div dir="rtl" className={`  flex  w-full justify-center`}>
            <div className="lecture-session-details-wrapper   flex h-auto w-full items-start overflow-hidden rounded-2xl border-2 border-secondary p-8  shadow-xl justify-between gap-14 md:flex-col-reverse md:items-center">
              <div className="lock-info relative w-1/2 md:w-full  rounded-xl p-5 flex justify-center">
                <div className="w-[300px] relative py-3 ">
                  <FontAwesomeIcon
                    className=" h-full w-full text-9xl opacity-10 absolute right-0 "
                    icon={faLock}
                  />
                  <h2 className="mb-10 mt-3 text-start text-3xl font-bold  ">
                    تحتوي هذه المحاضرة علي:
                  </h2>
                  <ul className="flex flex-col items-start justify-start  gap-8">
                    <li className="flex items-center justify-start gap-4 ">
                      <FontAwesomeIcon icon={faTv} />
                      <p className=" ">فيديوهات.</p>
                    </li>
                    <li className="flex items-center justify-start gap-4">
                      <FontAwesomeIcon icon={faFileLines} /> <p>امتحانات.</p>
                    </li>

                    <li className="flex items-center justify-start gap-4">
                      <FontAwesomeIcon icon={faMobile} />{" "}
                      <p>متاح عن طريق الموبايل والكومبيوتر.</p>
                    </li>
                  </ul>
                </div>
              </div>

              {LectureInfoSuccess?.data?.session_info?.price !== 0 ? (
                <div className="form w-1/2 md:w-full  inset-0 flex h-full  flex-col items-center justify-between p-5">
                  <form
                    method="post"
                    onSubmit={handleSubmit(handleBuyLecture)}
                    className="flex  w-[300px] flex-col items-center gap-8"
                  >
                    <h3 className="price  my-5 text-center text-3xl font-bold">
                      <span>
                        {" "}
                        {LectureInfoSuccess?.data?.session_info?.price}
                      </span>{" "}
                      <span>جنية</span>
                    </h3>
                    <input
                      className="code-input h-16 w-1/2 rounded-lg pr-7"
                      placeholder="Add your code"
                      type="text"
                      name="code"
                      {...register("code", {
                        required: true,
                        maxLength: 255,
                        minLength: 1,
                        pattern: /^[A-Za-z0-9-_]*$/,
                      })}
                    />
                    {errors.code && (
                      <p className="w-full pb-4 text-center text-[12px] text-red-900 ">
                        {errors.code.type === "required" &&
                          "برجاء ملئ هذا الحقل"}
                        {errors.code.type === "maxLength" &&
                          "أقصي عدد للحروف هو ٢٥٥ حرف"}
                        {errors.code.type === "minLength" &&
                          " من فضلك قم بكتابة حرف واحد علي الاقل"}
                        {errors.code.type === "pattern" &&
                          " يسمح فقط باستخدام الحروف الإنجليزية والأرقام"}
                      </p>
                    )}
                    {
                      //!-------server errors -----

                      buyLectureErrors &&
                        buyLectureErrors?.response?.data?.errors?.code && (
                          <p className="w-full text-end text-[12px] text-red-900  ">
                            {buyLectureErrors?.response?.data?.errors?.code[0]}
                          </p>
                        )
                    }
                    {/** submit */}

                    <button
                      disabled={
                        !isValid || (submitLoading && state.buyLecture.flag)
                      }
                      className=" submit mt-6 w-full "
                      type="submit"
                    >
                      {submitLoading && state.buyLecture.flag ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        "اشتري المحاضرة"
                      )}
                    </button>
                  </form>
                </div>
              ) : (
                <p className="w-1/2 md:w-full  inset-0 flex h-full items-center justify-center font-bold text-3xl">
                  محاضرة مجانية
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      {/**
       * //!---- lecture homework  quiz & PDFs -----
       */}
      {LectureInfoSuccess?.data?.session_info?.is_buy === 1 &&
        (LectureInfoSuccess?.data?.session_history?.is_hw === 1 ||
          LectureInfoSuccess?.data?.session_history?.is_quiz === 1) && (
          <section className="mt-24 flex w-full items-start justify-center gap-4 rounded-2xl border-2 border-secondary py-5 dark:bg-dark dark:text-light md:flex-col-reverse md:items-center md:justify-start">
            {/**
             * //!---- homework -----
             */}
            {LectureInfoSuccess?.data?.session_history?.is_hw === 1 && (
              <div className="homework h-96 w-1/3 border-r-2 border-r-secondary p-8 py-4 md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
                <div className="mb-12 flex justify-between">
                  {LectureInfoSuccess?.data?.session_history?.is_hw_result ===
                  1 ? (
                    <Link
                      to={`/home/lectures/modal-answer/${lectureID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      نموذج الإجابة
                    </Link>
                  ) : (
                    <Link
                      to={`/home/homework/${lectureID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      ابدأ الواجب
                    </Link>
                  )}

                  <h2 className=" w-fit text-end text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                    <span className="mr-4">الواجب</span>
                    <FontAwesomeIcon icon={faFilePen} />{" "}
                  </h2>
                </div>
                {LectureInfoSuccess?.data?.session_history?.is_hw_result ===
                  1 && (
                  <div className="student-quiz-info flex w-full flex-col gap-10">
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.hw_degree}
                      </span>
                      <span className="font-semibold">الدرجة</span>
                    </h2>
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.averagehw}
                      </span>
                      <span className="font-semibold">متوسط الدرجات</span>
                    </h2>
                    <h2 className="flex w-full items-center justify-between ">
                      <span>
                        {LectureInfoSuccess?.data?.session_history.rankhw}
                      </span>
                      <span className="font-semibold">
                        ترتيب الطالب علي المحاضرة
                      </span>
                    </h2>
                  </div>
                )}
              </div>
            )}
            {/**
             * //!---- quiz -----
             */}
            {LectureInfoSuccess?.data?.session_history?.is_quiz === 1 && (
              <div className="quiz h-96 w-1/3 border-l-2 border-l-secondary  p-8 py-4 md:w-full md:border-r-0 md:border-t-2 md:border-t-secondary md:pt-10">
                <div className="mb-12 flex justify-between">
                  {LectureInfoSuccess?.data?.session_history?.is_quiz_result ===
                    1 &&
                  LectureInfoSuccess?.data?.session_history?.quiz_done === 1 ? (
                    <Link
                      to={`/home/lectures/modal-answer/${lectureID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      نموذج الإجابة
                    </Link>
                  ) : (
                    <Link
                      to={`/home/quiz/${lectureID}`}
                      className=" w-fit  rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                    >
                      ابدأ الإمتحان
                    </Link>
                  )}

                  <h2 className=" w-fit text-end text-3xl font-semibold	 underline decoration-wavy underline-offset-8 ">
                    <span className="mr-4">الإمتحان</span>
                    <FontAwesomeIcon icon={faFileSignature} />
                  </h2>
                </div>
                {LectureInfoSuccess?.data?.session_history?.is_quiz_result ===
                  1 &&
                  LectureInfoSuccess?.data?.session_history?.quiz_done ===
                    1 && (
                    <div className="student-quiz-info flex w-full flex-col gap-10">
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {
                            LectureInfoSuccess?.data?.session_history
                              .quiz_degree
                          }
                        </span>
                        <span className="font-semibold">الدرجة</span>
                      </h2>
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {LectureInfoSuccess?.data?.session_history.averageqz}
                        </span>
                        <span className="font-semibold">متوسط الدرجات</span>
                      </h2>
                      <h2 className="flex w-full items-center justify-between ">
                        <span>
                          {LectureInfoSuccess?.data?.session_history.rankqz}
                        </span>
                        <span className="font-semibold">
                          ترتيب الطالب علي المحاضرة
                        </span>
                      </h2>
                    </div>
                  )}
              </div>
            )}
          </section>
        )}
      {/**
       * //!---- lecture-bottom -----
       */}
      <div className="lecture-bottom video-playlist  mt-24 flex w-full items-start justify-between gap-24 lg:gap-16 md:flex-col-reverse md:items-center">
        <div className="video flex  w-2/3  md:w-full md:justify-center ">
          <div
            className={`iframe-wrapper aspect-video overflow-hidden rounded-2xl  ${
              state.currentVideo !== null && "border-secondary border-2 "
            } w-full`}
          >
            <iframe
              src={state.src}
              width="100%"
              height="100%"
              title="YouTube video player"
              frameBorder="0"
              //style={{ aspectRatio: "16 / 9" }}
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>

        <div className="playlist w-1/3  md:w-full">
          <div
            className={`toggle-playlist w-full overflow-hidden rounded-2xl border-2  border-secondary  ${
              state.collapse ? "h-[64px] bg-secondary text-light " : "h-[465px]"
            } transition-{height} duration-500 ease-in-out `}
          >
            <div
              className="toggle-playlist flex h-[64px] w-full cursor-pointer items-center justify-between gap-8 border-b-2 border-b-secondary  px-8 "
              onClick={() =>
                dispatch({
                  type: "setCollapse",
                  payload: !state.collapse,
                })
              }
            >
              <div dir="rtl" className="toggle-right font-semibold"></div>
              <div className="toggle-left flex items-center justify-between gap-5">
                <span className="text- font-semibold">محتوي المحاضرة</span>
                <FontAwesomeIcon
                  className={`${
                    state.collapse ? "rotate-180" : ""
                  } text-3xl transition-transform duration-500 ease-in-out`}
                  icon={faChevronUp}
                />
              </div>
            </div>
            <div
              className={`playlist flex h-[400px] w-full flex-col gap-7 overflow-y-auto p-8 transition-opacity duration-500 ease-in-out ${
                state.collapse ? "opacity-0 " : "opacity-100"
              }`}
            >
              {LectureInfoSuccess?.data?.videos?.map((video, index) => {
                let src =
                  video.platform === "youtube"
                    ? `https://www.youtube.com/embed/${video.vdo_id}`
                    : video.platform === "vimeo"
                    ? `https://player.vimeo.com/video/${video.vdo_id}`
                    : `https://player.vdocipher.com/v2/?otp=${video.OTP}&playbackInfo=${video.playbackInfo}`;

                return (
                  <div
                    className={`single-video w-full  ${
                      video.is_public === 1 ||
                      LectureInfoSuccess?.data?.session_info?.is_buy === 1
                        ? "cursor-pointer "
                        : " cursor-not-allowed"
                    }`}
                    key={video.key}
                  >
                    <div
                      onClick={() => {
                        dispatch({
                          type: "setCurrentVideo",
                          payload: index,
                        });
                        dispatch({
                          type: "setSrc",
                          payload: src,
                        });
                      }}
                      className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                        video.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? "hover:bg-secondary hover:text-white "
                          : " pointer-events-none"
                      }
                          ${
                            state.currentVideo === index &&
                            " bg-secondary text-white"
                          }`}
                    >
                      <div
                        dir="rtl"
                        className={`above flex w-full items-center justify-between gap-2`}
                      >
                        <div className="above-title w-session flex items-center justify-start gap-7">
                          <FontAwesomeIcon icon={faVideo} />{" "}
                          <p className="text- w-11/12 truncate hover:text-clip ">
                            {index + 1}. {video.name}
                          </p>
                        </div>
                        <div className="above-icon flex w-[20px] justify-end">
                          <FontAwesomeIcon
                            icon={
                              video.is_public === 1 ||
                              LectureInfoSuccess?.data?.session_info?.is_buy ===
                                1
                                ? faLockOpen
                                : faUnlockKeyhole
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/**
               * //!---- PDFs -----
               */}
              {LectureInfoSuccess?.data?.attachments?.map(
                (attachment, index) => {
                  return (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <a
                      href={
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? attachment.address
                          : null
                      }
                      download={attachment.name}
                      rel="noreferrer"
                      target={
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? "_blank"
                          : null
                      }
                      className={`single-attachment w-full  ${
                        attachment.is_public === 1 ||
                        LectureInfoSuccess?.data?.session_info?.is_buy === 1
                          ? "cursor-pointer "
                          : " cursor-not-allowed"
                      }`}
                      key={attachment.key}
                    >
                      <div
                        className={`h-full w-full  rounded-xl p-4 transition-all duration-200 ease-in-out ${
                          attachment.is_public === 1 ||
                          LectureInfoSuccess?.data?.session_info?.is_buy === 1
                            ? ""
                            : " pointer-events-none"
                        }
                          hover:bg-secondary hover:text-white`}
                      >
                        <div
                          dir="rtl"
                          className={`above flex w-full items-center justify-between gap-2`}
                        >
                          <div className="above-title w-session flex items-center justify-start gap-7">
                            <FontAwesomeIcon icon={faFileLines} />{" "}
                            <p className="text- w-11/12 truncate hover:text-clip ">
                              {index + 1}. {attachment.name}
                            </p>
                          </div>
                          <div className="above-icon flex w-[20px] justify-end">
                            <FontAwesomeIcon
                              icon={
                                attachment.is_public === 1 ||
                                LectureInfoSuccess?.data?.session_info
                                  ?.is_buy === 1
                                  ? faLockOpen
                                  : faUnlockKeyhole
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StudentLecture;
