import { useEffect, useState } from "react";
import SingleCourse from "../../../../MainComponents/SingleCourse";
import SingleChapter from "../../../../MainComponents/SingleChapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { Loader } from "../../../../MainComponents";

function AdminLectures() {
  const navigate = useNavigate();
  const AuthState = useAuthState();
  const [reFetch, setReFetch] = useState(false);
  const [allLecturesData, allLecturesErrors, allLecturesloading] = useAxios(
    process.env.REACT_APP_ADMIN_LECTURES_TAB_API,
    "GET",
    "GET",
    reFetch
  );

  return (
    <section
      className={`  h-auto  w-full  px-20 py-20  dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="المحاضرات | Mr Hossam Shaaban"></HelmetTags>
      <div className="mb-32 flex flex-col items-end gap-20">
        <h2 className="w-full text-end text-4xl font-bold md:text-center">
          المحاضرات
        </h2>{" "}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_add")
          )) && (
          <div className="flex w-full justify-end gap-10 md:justify-evenly">
            <button
              onClick={() => navigate("/admin/lectures/add-lecture")}
              className="ml-4 rounded-xl border-2 border-secondary bg-light px-2 py-1 font-semibold text-secondary transition-all duration-300 hover:bg-secondary hover:text-light   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              اضافة محاضرة
            </button>
            <button
              onClick={() => navigate("/admin/lectures/add-chapter")}
              className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 "
            >
              <FontAwesomeIcon className="mr-4" icon={faPlus} />
              اضافة فصل
            </button>
          </div>
        )}
      </div>

      {allLecturesloading ? (
        <Loader />
      ) : (
        <div className="lectures grid-auto-fit">
          {allLecturesData?.data?.length === 0 ? (
            <p>لا يوجد محاضرات</p>
          ) : (
            allLecturesData?.data?.map((Card) => {
              if (Card.is_chapter) {
                return (
                  <SingleChapter
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    chapter={Card}
                    key={Card.key}
                  />
                );
              } else {
                return (
                  <SingleCourse
                    reFetch={reFetch}
                    setReFetch={setReFetch}
                    admin
                    lecture={Card}
                    key={Card.key}
                  />
                );
              }
            })
          )}
        </div>
      )}
    </section>
  );
}

export default AdminLectures;
