import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";
import useCountDown from "./Hooks/useCountDown";

function SingleCourse({
  lecture,
  admin,
  exam,
  reFetch,
  setReFetch,
  notSigned,
}) {
  const { days, hours, minutes, seconds } = useCountDown(lecture?.expire_at);

  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Lecture -------

  const apiDelete = exam
    ? process.env.REACT_APP_ADMIN_DELETE_TRIAL_API
    : process.env.REACT_APP_ADMIN_DELETE_LECTURE_API;
  const [deleteLectureSuccess, deleteLectureErrors, deleteloading] = useAxios(
    apiDelete,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteLecture(id) {
    setSubmitDelete({
      flag: "deleteLecture",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteLectureSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteLectureSuccess]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="course min-h-[420px] w-[350px]  rounded-[25px] border-2 border-secondary bg-white p-8 shadow-lg sm:w-[300px] "
    >
      <div className="img relative h-[170px] w-full cursor-pointer overflow-hidden rounded-[20px]">
        <img
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                  ? `${
                      exam
                        ? `/admin/exams/${lecture?.key}`
                        : `/admin/lectures/sessions/${lecture?.key}`
                    }`
                  : `${
                      exam
                        ? `/home/exams/${lecture?.key}`
                        : `/home/lectures/sessions/${lecture?.key}`
                    }`
              }`
            )
          }
          className="h-full w-full object-cover  duration-300 hover:scale-125"
          src={lecture?.img}
          alt={lecture?.title}
        />

        <div className="stage-wrapper absolute bottom-4 left-4 flex h-10 w-fit items-center justify-center gap-1  rounded-xl bg-light px-3  font-semibold">
          <p className="stage flex flex-row-reverse gap-1 text-xl font-semibold">
            <span>{lecture?.price} </span>
            <span>جنية</span>
          </p>
        </div>
        {exam && (
          <div className="stage-wrapper absolute bottom-4 right-4 flex h-10 w-fit items-center justify-center gap-1  rounded-xl bg-light px-3  font-semibold">
            <p className="stage flex flex-row-reverse gap-1 text-xl font-semibold">
              <span>{lecture?.duration} </span>
              <span>دقيقة</span>
            </p>
          </div>
        )}
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("lecture_show_creator")
          )) &&
          admin &&
          !exam && (
            <div className="creator pointer-events-none absolute right-4 top-4 flex h-10 w-fit items-center justify-center rounded-xl bg-light px-3 text-xl font-semibold ">
              {lecture?.created_by} بواسطة
            </div>
          )}
      </div>
      <div className="course-details mt-7 flex flex-col items-end text-center">
        <h3
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                  ? `${
                      exam
                        ? `/admin/exams/${lecture?.key}`
                        : `/admin/lectures/sessions/${lecture?.key}`
                    }`
                  : `${
                      exam
                        ? `/home/exams/${lecture?.key}`
                        : `/home/lectures/sessions/${lecture?.key}`
                    }`
              }`
            )
          }
          className={`title mb-7 w-full cursor-pointer text-secondary ${
            admin ? "text-end" : "text-center"
          }   text-2xl  font-bold`}
        >
          {lecture?.title?.substring(0, 33)}
        </h3>
        <p
          dir="rtl"
          className="description w-full text-2xl font-semibold leading-relaxed text-secondary/70"
        >
          {lecture?.description?.substring(0, 70)}
        </p>

        {!admin && !exam && (
          <>
            {lecture?.is_buy === 1 && (
              <div
                dir="rtl"
                className="w-full font-bold p-2 border-[#fff] bg-[#fff] rounded-md mt-4"
              >
                <p className="mb-2">تنتهي بعد : </p>
                <p className="text-center text-2xl">
                  {days}: أيام| {hours}: ساعات | {minutes}: دقائق | {seconds} :
                  ثواني
                </p>
              </div>
            )}
          </>
        )}

        <div
          className={`visibility-cta mt-5 flex w-full border-t-2 border-secondary  ${
            admin ? "justify-between" : "justify-center"
          } items-center pb-4 pt-12`}
        >
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture")
            )) &&
            admin && (
              <div className="visibility pointer-events-none flex h-10 w-fit  items-center justify-center rounded-xl bg-secondary px-3 text-xl font-semibold text-light">
                {lecture?.visibility === 0 ? "مخفي" : "ظاهر"}
              </div>
            )}{" "}
          {admin ? (
            <div className="admin-options flex w-full justify-end gap-4">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    `${exam ? "exam_delete" : "lecture_delete"}`
                  )
                )) && (
                <Popconfirm
                  okText="تأكيد"
                  okType="danger"
                  cancelText="إلغاء"
                  color="#fecaca"
                  //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDeleteLecture(lecture?.key)}
                  title={
                    exam
                      ? "هل تريد بالفعل حذف الإمتحان؟"
                      : "هل تريد بالفعل حذف المحاضرة؟"
                  }
                >
                  <button className="w-24 rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90">
                    حذف
                  </button>
                </Popconfirm>
              )}

              {exam && (
                <Link
                  to={`/admin/exams/all-students/${lecture?.key}`}
                  className="w-24 rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                >
                  الطلاب
                </Link>
              )}

              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    `${exam ? "exam_edit" : "lecture_edit"}`
                  )
                )) &&
                admin && (
                  <Link
                    to={`${
                      exam
                        ? `/admin/exams/edit-exam/${lecture?.key}`
                        : `/admin/lectures/edit-lecture/${lecture?.key}`
                    }`}
                    className="w-24 rounded-2xl border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90"
                  >
                    تعديل
                  </Link>
                )}
            </div>
          ) : (
            <Link
              to={
                notSigned
                  ? "/signin"
                  : `${
                      exam
                        ? `/home/exams/${lecture?.key}`
                        : `/home/lectures/sessions/${lecture?.key}`
                    }`
              }
              className="cta flex cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-accent  bg-accent   px-3 py-1  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90"
            >
              {lecture?.is_buy === 1 ? (
                <>{exam ? "مشاهدة الإختبار" : "مشاهدة المحاضرة"}</>
              ) : (
                <>
                  <FontAwesomeIcon
                    className="h-6 w-6  "
                    icon={faAnglesLeft}
                    fade
                  />
                  انضم الان
                </>
              )}
            </Link>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default SingleCourse;
