import React from "react";

import StudentChangePassword from "./components/StudentChangePassword";
import StudentChangeImage from "./components/StudentChangeImage";

const Profile = () => {
  return (
    <section className="h-auto width flex flex-col items-center">
      {/* <StudentChangeImage /> */}
      <StudentChangePassword />
    </section>
  );
};

export default Profile;
