import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useAuthDispatch } from "../../../MainComponents/GlobalContext.jsx";
import axios from "axios";
import { toast } from "react-hot-toast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import logoLight from "../../../assets/logo-light.png";

function SignIn({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [hide, setHide] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [forgetPassowrdLoading, setForgetPassowrdLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const handleSignIn = (data) => {
    setSubmitLoading(true);
    async function postData() {
      try {
        let res = await axios.post(
          admin
            ? process.env.REACT_APP_ADMIN_LOGIN_API
            : process.env.REACT_APP_LOGIN_API,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        let userData = await res.data;
        setSubmitLoading(false);
        localStorage.setItem("userData", JSON.stringify(userData));
        AuthDispatch({ type: "setUserData", payload: userData });

        // set axios
        axios.defaults.headers.common["Authorization"] = JSON.parse(
          localStorage.userData
        ).student_token;

        toast.success(userData?.message);
        reset();
        // navigate(`${admin ? "/admin/dashboard" : "/home"}`, { replace: true });
      } catch (error) {
        setSubmitLoading(false);
        setServerErrors(error?.response?.data?.error);
        toast.error(
          error?.response?.data?.error || "حدث خطأ ما من فضلك حاول مرة أخري"
        );
      }
    }
    postData();
  };

  const handleForgotPassword = (data) => {
    setForgetPassowrdLoading(true);
    //!  if success
    setForgotPassword(false);
    setForgetPassowrdLoading(false);
    //!  if error
    setForgetPassowrdLoading(false);
    setServerErrors();

    reset();
  };

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-24 py-10 dark:bg-dark sm:py-14">
      <HelmetTags
        title="تسجيل الدخول | Mr Hossam Shaaban"
        description="تسجيل الدخول | Mr Hossam Shaaban"
        index
      >
        <link rel="canonical" href="https://geologyhossamshaaban.com/signin" />
      </HelmetTags>

      <div
        //!welcome-message
        className="welcome-message  flex flex-col items-center  justify-center"
      >
        <Link className="h-24 w-24 text-4xl" to="/">
          <img className="h-full w-full " src={logoLight} alt="logo" />
        </Link>
        <h1 className="mb-5 mt-10 font-bold ">
          {forgotPassword
            ? "إعادة تعيين كلمة المرور | Mr Hossam Shaaban"
            : "تسجيل دخول  | Mr Hossam Shaaban"}
        </h1>
        <h2 className="opacity-80">
          {forgotPassword
            ? "الرجاء إدخال رقم هاتفك المحمول وسنرسل لك رمز إعادة تعيين كلمة المرور"
            : "مرحبًا بعودتك! الرجاء إدخال المعلومات الخاصة بك "}{" "}
        </h2>
      </div>

      {forgotPassword ? (
        <div className="sign-in-form flex w-full flex-col items-center ">
          <form
            //!form forgot password
            onSubmit={handleSubmit(handleForgotPassword)}
            method="post"
            className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl bg-form-background  p-16 shadow-lg shadow-red-200/50 sm:w-3/4"
          >
            {/**
             * //!Username filed
             *
             */}
            <div className=" flex w-full flex-col items-end justify-center gap-2">
              <label htmlFor="username"> رقم الهاتف </label>
              <input
                placeholder="رقم الهاتف"
                className="signin-inputs w-full "
                type="text"
                id="mobilenumberreset"
                name="mobilenumberreset"
                autoComplete="on"
                {...register("mobilenumberreset", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                })}
              />
              {errors.mobilenumberreset && (
                <p className="text-[12px] text-red-900 ">
                  {errors.mobilenumberreset.type === "required" &&
                    "برجاء ادخال رقم الهاتف"}
                  {errors.mobilenumberreset.type === "pattern" &&
                    " رقم الهاتف هذا غير صحيح"}
                  {errors.mobilenumberreset.type === "maxLength" &&
                    " رقم الهاتف هذا غير صحيح"}
                </p>
              )}
              {/* {
                //!--- server errors --------
                serverErrors && (
                  <p className="text-[12px] text-red-900 ">{serverErrors}</p>
                )
              } */}
            </div>

            <button
              disabled={!isValid || forgetPassowrdLoading}
              className=" submit max-w-60 mt-10 "
              type="submit"
            >
              {forgetPassowrdLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "ارسال الرمز"
              )}
            </button>
          </form>
          <span
            className="my-10 w-full cursor-pointer text-center text-[14px] font-bold"
            onClick={() => {
              setForgetPassowrdLoading(false);
              setForgotPassword(false);
            }}
          >
            تسجيل الدخول بدلا من ذلك{" "}
          </span>
        </div>
      ) : (
        <div className="sign-in-form flex w-full flex-col items-center ">
          <form
            //!form
            onSubmit={handleSubmit(handleSignIn)}
            method="post"
            className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl bg-form-background  p-16 shadow-lg shadow-red-200/50 sm:w-3/4"
          >
            {/**
             * //!Username filed
             *
             */}
            <div className=" flex w-full flex-col items-end justify-center gap-2">
              <label htmlFor="username"> اسم المستخدم</label>
              <input
                className="signin-inputs max-w-60 "
                type="text"
                id="username"
                placeholder="اسم المستخدم"
                name="username"
                autoComplete="on"
                {...register("username", {
                  required: true,
                  pattern: /^[A-Za-z0-9-_]*$/,
                  maxLength: 20,
                  minLength: 3,
                })}
              />
              {errors.username && (
                <p className="text-[12px] text-red-900 ">
                  {errors.username.type === "required" &&
                    "برجاء ادخال اسم المستخدم أو الايميل"}
                  {errors.username.type === "pattern" &&
                    " يسمح باستخدام الحروف والارقام فقط"}
                  {errors.username.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٢٠ حرف"}
                  {errors.username.type === "minLength" &&
                    "أقل عدد للحروف هو ٣ حروف"}
                </p>
              )}
            </div>
            {/**
             * //!Password filed
             *  */}

            <div className=" mb-4 mt-10 flex w-full flex-col items-end justify-center gap-2">
              <label htmlFor="password">كلمه المرور</label>
              <div
                //!input password relative
                className="relative-hide relative h-auto w-full"
              >
                <input
                  id="password"
                  className="signin-inputs max-w-60 "
                  type={`${hide ? "text" : "password"}`}
                  placeholder="كلمة المرور"
                  autoComplete="on"
                  name="password"
                  {...register("password", {
                    required: true,
                    maxLength: 25,
                    minLength: 6,
                  })}
                />
                <div
                  //!eye icons
                  onClick={() => setHide(!hide)}
                  className="hide-pass absolute bottom-1/2 left-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
                >
                  {hide ? (
                    <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                  ) : (
                    <FontAwesomeIcon
                      className="h-full w-full"
                      icon={faEyeSlash}
                    />
                  )}
                </div>
              </div>
              {errors.password && (
                <p className="text-[12px] text-red-900 ">
                  {errors.password.type === "required" &&
                    "برجاء ادخال كلمه المرور"}
                  {errors.password.type === "maxLength" &&
                    "أقصي عدد للحروف هو ٢٥ حرف"}
                  {errors.password.type === "minLength" &&
                    "أقل عدد للحروف هو ٦ احرف"}
                </p>
              )}

              {
                //!--- server errors --------
                serverErrors && (
                  <p className="text-[12px] text-red-900 ">{serverErrors}</p>
                )
              }
            </div>
            <div className="flex w-full justify-end  ">
              <span
                className="w-fit cursor-pointer text-[13px] underline"
                onClick={() => {
                  setSubmitLoading(false);
                  setForgotPassword(true);
                }}
              >
                نسيت كلمة المرور؟{" "}
              </span>
            </div>

            <button
              disabled={!isValid || submitLoading}
              className=" submit max-w-60 mt-10 "
              type="submit"
            >
              {submitLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                "تسجيل الدخول"
              )}
            </button>
          </form>
          {!admin && (
            <div className="my-10 w-full text-center text-[14px] ">
              ليس لديك حساب ؟{" "}
              <Link className="font-bold" to="/register">
                انشئ حساب الان{" "}
              </Link>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

export default SignIn;
